/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { FindSpace, TheSeo } from "x"

const SearchPage = ({ location }) => (
	<Fragment>
		<TheSeo title="Search Listings" />
		<FindSpace />
	</Fragment>
)

export default SearchPage
